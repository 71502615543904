.error-container {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    margin-bottom: 50px;
    font-size: 50px;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  p {
    display: inline-block;
    line-height: 1.2;
    transition: line-height 0.2s, width 0.2s;
    overflow: hidden;
  }
}
